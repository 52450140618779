import axios from 'axios';
import { IAuthResponse } from '@/store/user/user.interface';
import { removeTokensStorage, saveToStorage } from './auth.helper';
import { API_URL } from '@/helpers/variables';

export const AuthService = {
  async register(email: string, password: string, login: string, first_name: string, last_name: string) {
    const response = await axios.post<IAuthResponse>(`${API_URL}users/`, {
      email,
      password,
      login,
      first_name,
      last_name,
    });

    if (response.data.token) {
      saveToStorage(response.data);
    }

    return response;
  },

  async login(email: string, password: string) {
    const response = await axios.post<IAuthResponse>(`${API_URL}auth/`, {
      email,
      password,
    });

    if (response.data.token) {
      saveToStorage(response.data);
    }

    return response;
  },

  logout() {
    removeTokensStorage();
    localStorage.removeItem('user');
  },
};

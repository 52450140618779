import { IAuthResponse, InterfaceEmailPassword } from './user.interface';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
// import { errorCatch } from 'api/api.helpers'

import { AuthService } from '@/services/auth/auth.service';
import { toastError } from '@/helpers/withToastrErrorRedux';

export const registration = createAsyncThunk<IAuthResponse, any>('auth/register', async ({ email, password, login, first_name, last_name }, thunkAPI) => {
  try {
    const response = await AuthService.register(email, password, login, first_name, last_name);
    toastr.success('Регистрация', 'прошла успешно!');
    return response.data;
  } catch (error) {
    toastError(error, 'Ошибка регистрации');
    return thunkAPI.rejectWithValue(error);
  }
});

export const login = createAsyncThunk<IAuthResponse, InterfaceEmailPassword>('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    const response = await AuthService.login(email, password);
    toastr.success('Авторизация', 'прошла успешно!');
    return response.data;
  } catch (error) {
    toastError(error, 'Ошибка авторизации');
    return thunkAPI.rejectWithValue(error);
  }
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

// export const checkAuth = createAsyncThunk<IAuthResponse>(
// 	'auth/check-auth',
// 	async (_, thunkAPI) => {
// 		try {
// 			const response = await AuthService.getNewTokens()
// 			return response.data
// 		} catch (error) {
// 			if (errorCatch(error) === 'jwt expired') {
// 				toastr.error(
// 					'Logout',
// 					'Your authorizaiton is finished, plz sign in again'
// 				)
// 				thunkAPI.dispatch(logout())
// 			}
// 			return thunkAPI.rejectWithValue(error)
// 		}
// 	}
// )

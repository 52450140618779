import '@/styles/globals.scss';
import type { AppProps } from 'next/app';
import MainProvider from '@/providers/MainProvider';
import NextNProgress from 'nextjs-progressbar';
import Error from 'next/error';

export default function App({ Component, pageProps }: AppProps) {
  if (pageProps.error) {
    return <Error statusCode={pageProps.error.statusCode} title={pageProps.error.message} />;
  }

  return (
    <MainProvider Component={Component}>
      <NextNProgress color="#9189ff" startPosition={0.5} stopDelayMs={100} height={4} showOnShallow={true} />
      <Component {...pageProps} />
    </MainProvider>
  );
}

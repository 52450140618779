// import AuthProvider from './AuthProvider/AuthProvider'
// import HeadProvider from './HeadProvider/HeadProvider'
import { FC } from 'react';
// import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux';
import { store } from '@/store/store';
import ReduxToastr from './ReduxToast';

const MainProvider: FC<any> = ({ children, Component }) => {
  return (
    <Provider store={store}>
      <ReduxToastr />
      {/* <AuthProvider Component={Component}> */}
      {children}
      {/* </AuthProvider> */}
    </Provider>
  );
};

export default MainProvider;

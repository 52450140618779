import { createSlice } from '@reduxjs/toolkit';
import { getStoreLocal } from '@/helpers/localStorage';

import { login, logout, registration } from './user.actions';
import { IUserInitialState } from './user.interface';

const initialState: IUserInitialState = {
  user: getStoreLocal('user'),
  isLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registration.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        // Пока не добавили поле name/emil на бэке проверка по токену
        // state.user = payload.token;
      })
      .addCase(registration.rejected, (state) => {
        state.isLoading = false;
        state.user = null;
      })

      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        const userObject = { user_email: payload.user_email };

        state.isLoading = false;
        state.user = userObject;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.user = null;
      })

      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null;
      });

    // .addCase(checkAuth.fulfilled, (state, { payload }) => {
    // 	state.user = payload.user
    // })
  },
});

export const { reducer } = userSlice;
